require('dotenv').config();

import Vue from 'vue';
import App from './App.vue';
import store from './store';

import moment from 'moment';
import xss from 'xss';
import 'moment/locale/fr';

import router from './routing/router';
import utils from './helpers/utils';
import constants from './helpers/constants';

import i18n from '@/plugins/i18n';
import VueMeta from 'vue-meta';
import Axios from 'axios';
import Multiselect from 'vue-multiselect';
import Toasted from 'vue-toasted';
import VueTheMask from 'vue-the-mask';
import VueMatchHeights from 'vue-match-heights';
import VueMoment from 'vue-moment';
import VueGtm from '@gtm-support/vue2-gtm';

import { VuePlausible } from 'vue-plausible';
import VueTippy, { TippyComponent } from "vue-tippy";
import VueTimepicker from 'vue2-timepicker';
import VueSegmentAnalytics from 'vue-segment-analytics'
import VueScrollTo from 'vue-scrollto';
import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-socket.io'
import browserDetect from "vue-browser-detect-plugin";
import 'vue2-timepicker/dist/VueTimepicker.css'

import * as Sentry from "@sentry/vue";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation, faSadTear, faGear, faEarthAmerica, faAngleDown, faHandshakeAngle, faUser, faTimes, faArrowLeft, faArrowRight, faBriefcase, faShop, faBars, faCoins, faCircleQuestion, faCircleXmark, faCalculator, faAddressBook, faWallet, faCreditCard, faTriangleExclamation, faCircleCheck, faClipboard, faXmark, faSquareCheck, faSquareXmark, faCopy, faRightFromBracket, faComments, faPaperPlane, faSackDollar, faHeart, faCircleInfo, faUsers, faArrowUpRightFromSquare, faCloudArrowDown, faHeadset, faCheck, faEye, faEyeSlash, faArrowsRotate, faLock } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faSquare, } from '@fortawesome/free-regular-svg-icons';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons'

library.add(faCircleExclamation, faSadTear, faCircleCheck, faGear, faEarthAmerica, faAngleDown, faHandshakeAngle, faUser, faTimes, faArrowLeft, faArrowRight, faBriefcase, faShop, faCircle, faBars, faCoins, faCircleQuestion, faCircleXmark, faCalculator, faAddressBook, faWallet, faCreditCard, faCcVisa, faCcMastercard, faCcAmex, faTriangleExclamation, faClipboard, faXmark, faSquare, faSquareCheck, faSquareXmark, faCopy, faRightFromBracket, faComments, faPaperPlane, faSackDollar, faHeart, faCircleInfo, faUsers, faArrowUpRightFromSquare, faCloudArrowDown, faHeadset, faCheck, faEye, faEyeSlash, faArrowsRotate, faLock);
dom.watch();

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.use(VueMoment, { moment });
Vue.use(VueMatchHeights);
Vue.use(VueScrollTo);
Vue.use(VueTheMask);
Vue.use(VueDatePicker);
Vue.use(browserDetect);

Vue.use(new VueSocketIO({
  debug: process.env.VUE_APP_CONVERSE_API_DEBUG === 'true' ? true : false,
  connection: SocketIO(process.env.VUE_APP_CONVERSE_API_ENDPOINT, {
    path: '/socket',
    autoConnect: false
  })
}))

Vue.use(VueTippy, {
  placement: 'top-start',
  animation: 'fade',
  theme: 'custom',
  arrowType: 'round',
  arrow: true,
  ignoreAttributes: true,
  maxWidth: 1000
});

Vue.use(Toasted, {
  iconPack: 'fontawesome',
  position: 'bottom-right',
  singleton: true,
  duration: 3000,
  containerClass: 'likab-toast'
});

import '@/assets/sass/_styles.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './registerServiceWorker'

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
Axios.defaults.withCredentials = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$utils = utils;
Vue.prototype.$CONSTANTS = constants;
Vue.prototype.$xss = xss;

Vue.prototype.$axios.interceptors.request.use(
  request => {
    const token = store.getters.token;
    const ip = store.getters.ip;
    const version = process.env.VUE_APP_VERSION;

    if (token) {
      request.headers = Object.assign({
            Authorization: `${token}`
        }, request.headers);
    }

    if (ip) {
      request.headers = Object.assign({
          ip: `${ip}`
        }, request.headers);
    }

    if (ip) {
      request.headers = Object.assign({
          version: `${version}`
        }, request.headers);
    }

    if(Vue.prototype.$browserDetect && Vue.prototype.$browserDetect.meta && Vue.prototype.$browserDetect.meta.ua) {
        request.headers = Object.assign({
          'ua': `${Vue.prototype.$browserDetect.meta.ua}`
      }, request.headers);
    }

    if(request.data) {
      request.data = utils._cleanXSS(request.data);
    }

    return request;
  }
);

Vue.prototype.$axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if(doNotShowErrorMessage(error)) {
      return Promise.reject(error);
    }

    if(error.response === undefined) {
      Vue.prototype.$toasted.error(
        i18n.t('errors.backend.anErrorOccuredOnOurSide'),
        { icon: 'circle-exclamation', duration: 5000 }
      )
    }

    if (error.response && error.response.status === 403) {
      Vue.prototype.$toasted.error(
        i18n.t('errors.backend.unauthorized'),
        { icon: 'circle-exclamation' }
      )

      router.push({ name: 'dashboard' });
    }

    if (error.response && error.response.status === 401) {
        store.dispatch('logout');
        router.push({ name: 'login' });

        Vue.prototype.$toasted.error(
          i18n.t('errors.backend.session_expired'),
          { icon: 'circle-exclamation' }
        )
    }

    if(error.response && error.response.data && error.response.data.key) {
      Vue.prototype.$toasted.error(
        i18n.t(`errors.backend.${error.response.data.key}`),
        { icon: 'circle-exclamation' }
      )
    }else {
      Vue.prototype.$toasted.error(
        i18n.t('errors.backend.anErrorOccured'),
        { icon: 'circle-exclamation' }
      )
    }
    
    return Promise.reject(error);
  }
);

Vue.prototype.$screen = Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight
});

window.addEventListener('resize', () => {
    Vue.prototype.$screen.width = window.innerWidth;
    Vue.prototype.$screen.height = window.innerHeight;
});

if(process.env.VUE_APP_ENV !== 'local') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    release: process.env.VUE_APP_VERSION,
    integrations: [
      new HttpClientIntegration({
        failedRequestStatusCodes: [[400, 404], [500, 599]],
      }),
      new CaptureConsoleIntegration({
        levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
      })
    ],
    sendDefaultPii: true,
    beforeSend(event, hint) {
        if (hint.originalException === "Timeout") return null;
        return event;
    }
  });
}


if(process.env.VUE_APP_ENV === 'production') {
  Vue.use(VuePlausible, {
    domain: process.env.VUE_APP_PLAUSIBLE_DOMAIN,
  })
  
  Vue.$plausible.enableAutoPageviews();
  Vue.$plausible.enableAutoOutboundTracking();

  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: true,
    debug: process.env.VUE_APP_GTM_DEBUG === 'true', 
    vueRouter: router,
  });
  
  Vue.use(VueSegmentAnalytics, {
    id: process.env.VUE_APP_SEGMENT_WRITE_KEY,
    router
  })
}

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);
Vue.component("tippy", TippyComponent);
Vue.component("vue-timepicker", VueTimepicker)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

const doNotShowErrorMessage = (error) => {
  if(error.response && error.response.data && error.response.data.hide) {
    return true;
  } else if(error.response && error.response.data && error.response.data.error) {
    if(error.response.data.error.key === 'notEnoughCredits') { return true; }
  }

  return false;
}

export const bus = new Vue();