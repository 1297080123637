import jwt_decode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';
import xss from 'xss';

const cookies = require('js-cookie');

const allowed_partners = ['ape', 'crfmv'];

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.withCredentials = false;

const state = {
    id: '',
    name: '',
    email: '',
    scopes: [],

    currentProfile: '',
    currentProfileType: '',
    
    preferredCommunicationLanguage: '',
    
    accountIsVerified: null,
    onboardingProcessCompleted: null,

    token: '',
    age_group: '',

    sign_up_choice: '',
    
    created_at: '',
    reinitialize_temp_password: null,
    referral_code: '',
    referred_code: '',
    website_email: 'none',
    interested_in_volunteer: null,
    ref_partner: '',
    ip: 'none',

    geo: {
        postalCode: '',
        country: '',
        province: '',
        lat: '',
        long: ''
    },

    has_payment_method: false
}

const getters = {
    user_id: state => state.id,
    is_authenticated: state => state.token !== '' && state.id !== '',
    is_person: state => state.currentProfile === 'worker/volunteer',
    is_admin: state => state.scopes.includes('admin') && state.currentProfile === 'admin',
    isWorkProvider: state => state.currentProfile === 'workProvider',

    isAllowedWorker: state => state.scopes.includes('worker'),
    isAllowedVolunteer: state => state.scopes.includes('volunteer'),

    accountIsVerified: state => state.accountIsVerified,
    
    onboardingProcessCompleted: state => state.onboardingProcessCompleted,

    created_at: state => state.created_at,
    sign_up_choice: state => state.sign_up_choice,
    
    token: state => state.token,
    scopes: state => state.scopes,

    currentProfile: state => state.currentProfile,
    currentProfileType: state => state.currentProfileType,
    
    current_user_id: state => state.id,
    email: state => state.email,
    name: state => state.name,
    age_group: state => state.age_group,
    reinitialize_temp_password: state => state.reinitialize_temp_password,
    referral_code: state => state.referral_code,
    referred_code: state => state.referred_code,
    website_email: state => state.website_email,
    interested_in_volunteer: state => state.interested_in_volunteer,
    ref_partner: state => state.ref_partner,
    ip: state => state.ip,

    users_geolocation: state => state.geo,
    has_payment_method: state => state.has_payment_method,
};

const actions = {
    logout: ({commit}, router) => {
        commit('clearState');

        if(router) {
            router.push({name: 'login'});
        }
    },

    fetchToken: ({commit}, router) => {
        const token = cookies.get('token');
        if(token && validateToken(token)) {
            commit('setToken', token, router);
        }else {
            commit('clearState');
        }
    },

    setToken: ({commit}, token) => {
        commit('clearState');
        if(token && validateToken(token)) {
            cookies.set('token', token, { expires: 7, secure: true, sameSite: 'strict' });
            commit('setToken', token);
        }else {
            commit('clearState');
        }
    },

    fetch_referred_code: ({commit}) => {
        const referred_code = cookies.get('referred_code');
        if(referred_code) {
            commit('set_referred_code', { code: referred_code, override: false });
        }
    },

    set_referred_code: ({commit}, payload) => {
        commit('set_referred_code', payload);
    },

    fetch_ref_partner: ({commit}) => {
        const ref_partner = cookies.get('ref_partner_app');
        if(ref_partner) {
            commit('set_ref_partner', { ref_partner, override: false });
        }
    },

    set_ref_partner: ({commit}, payload) => {
        commit('set_ref_partner', payload);
    },

    set_website_email: ({commit}, payload) => {
        commit('set_website_email', payload);
    },
    
    fetch_website_email: ({commit}) => {
        const email = cookies.get('email_from_website');
        if(email) {
            commit('set_website_email', { email, override: false });
        }
    },

    set_ip: ({commit}, ip) => {
        commit('set_ip', { ip, override: true });
    },

    fetch_ip: ({commit}) => {
        const ip = cookies.get('ip');
        if(ip) {
            commit('set_ip', { ip, override: false });
        }
    },

    delete_referred_code: ({commit}) => {
        commit('delete_referred_code');
    },

    delete_ref_partner: ({commit}) => {
        commit('delete_referred_code');
    },

    delete_website_email: ({commit}) => {
        commit('delete_website_email');
    },

    removeToken: ({commit}) => {
        commit('clearState');
    }
};

const mutations = {
    setToken (state, token) {
        state.token = token;
        const payload = jwt_decode(token);

        if(payload.id && payload.fname) {
            state.id = payload.id;
            state.name = payload.fname;
            state.email = payload.email;

            if(payload.scopes)
                state.scopes = payload.scopes;

            if(payload.currentProfile)
                state.currentProfile = payload.currentProfile;

            if(payload.currentProfileType)
                state.currentProfileType = payload.currentProfileType

            if(payload.sign_up_choice)
                state.sign_up_choice = payload.sign_up_choice

            if(payload.preferredCommunicationLanguage)
                state.preferredCommunicationLanguage = payload.preferredCommunicationLanguage;

            if(payload.accountIsVerified !== null)
                state.accountIsVerified = payload.accountIsVerified;

            if(payload.onboardingProcessCompleted !== null)
                state.onboardingProcessCompleted = payload.onboardingProcessCompleted;

            if(payload.created_at !== null)
                state.created_at = moment(payload.created_at).format('x');

            if(payload.age_group !== null)
                state.age_group = payload.age_group;

            if(payload.reinitialize_temp_password !== null)
                state.reinitialize_temp_password = payload.reinitialize_temp_password;

            if(payload.referral_code !== null)
                state.referral_code = payload.referral_code;

            if(payload.interested_in_volunteer !== null)
                state.interested_in_volunteer = payload.interested_in_volunteer;

            if(payload.geo !== null)
                state.geo = payload.geo;

            if(payload.has_payment_method !== null)
                state.has_payment_method = payload.has_payment_method;
        }
    },

    clearState(state) {
        cookies.remove('token');

        state.token = '';
        state.id = '';
        state.name = '';
        state.email = '';
        state.scopes = [];
        state.currentProfile = '';
        state.currentProfileType = '';
        state.preferredCommunicationLanguage = '';
        state.accountIsVerified = null;
        state.onboardingProcessCompleted = null;
        state.created_at = null;
        state.age_group = '';
        state.reinitialize_temp_password = null;
        state.referral_code = null;
        state.website_email = null;
        state.geo = null;
        state.has_payment_method = null;
    },

    set_referred_code(state, payload) {
        const { code, override } = payload;
        state.referred_code = code.trim();

        if(override) {
            cookies.set('referred_code', code.trim(), { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    set_ref_partner(state, payload) {
        const { ref_partner, override } = payload;

        if(allowed_partners.includes(ref_partner.trim())) {
            state.ref_partner = ref_partner;
            if(override) {
                cookies.set('ref_partner_app', ref_partner.trim(), { expires: 7, secure: true, sameSite: 'strict' });
            }
        }
    },

    set_website_email(state, payload) {
        const { email, override } = payload;
        const parsed = xss(email.trim());
        state.website_email = parsed;

        if(override) {
            cookies.set('email_from_website', parsed, { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    set_ip(state, payload) {
        const { ip, override } = payload;
        state.ip = ip;

        if(override) {
            cookies.set('ip', ip, { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    delete_referred_code(state) {
        state.referred_code = '';
        cookies.remove('referred_code');
    },

    delete_ref_partner(state) {
        state.ref_partner = '';
        cookies.remove('ref_partner_app');
    },

    delete_website_email(state) {
        state.website_email = '';
        cookies.remove('email_from_website');
    }
};

const validateToken = (token) => {
    try {
        jwt_decode(token);
    } catch(err) {
        return false;
    }

    return true;
}

export default { state, getters, actions, mutations }