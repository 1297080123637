const moment = require('moment');

const time_regex = /^([0-2][0-9]:[0-5][0-9])$/

const validate_time_slot = (input) => {
    return time_regex.test(input);
}

const calculate_hours_per_week_from_work_times = (work_times) => {
    let total = 0;
    const day_in_minutes = 60 * 24;

    const valid_work_times = work_times.filter((work_time) => {
        return  work_time.timeSlots[0].start.value.length === 5 && 
                work_time.timeSlots[0].end.value.length === 5 &&
                !work_time.timeSlots[0].start.value.includes('m') &&
                !work_time.timeSlots[0].end.value.includes('m')
    })

    for(let i = 0; i < valid_work_times.length; i++) {
        const day = valid_work_times[i];
        for(let d = 0; d < day.timeSlots.length; d++) {
            const diff = moment(day.timeSlots[d]['start'].value, 'hh:mm').diff(moment(day.timeSlots[d]['end'].value, 'hh:mm'), 'minutes');
            if (diff < 0) { total += diff * -1; } else { total += (day_in_minutes - diff); }
        }
    }
    
    if(total < 15)
        total = 0;

    if(total === 15) { return 0.25 }
    if(total === 30) { return 0.5 }
    if(total === 45) { return 0.75 }
    
    const result = parseFloat(total / 60);

    if(isNaN(result)) { return 0; }
    else { return result }
}

const validate_phone_number = (input) => {
    if(!input) { return false; }
    return input.length === 10 && !isNaN(parseInt(input));
}

const re_order_work_days = (work_days) => {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const sort_days = function (a, b) {
        a = days.indexOf(a);
        b = days.indexOf(b);
        return a - b;
    };

    return work_days.sort(sort_days);
}

const is_date_today = (input) => {
    const today = new Date();
    const date = new Date(input);

    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getYear() === today.getYear();
}

export { validate_time_slot, calculate_hours_per_week_from_work_times, validate_phone_number, re_order_work_days, is_date_today }