const state = {
    currentStep: 1,
    choice: '',
    context: 'onboarding',
}

const getters = {
    currentStep: state => state.currentStep,
    choice: state => state.choice,
    context: state => state.context,

    worker: state => state.worker,
    workerOffer: state => state.workerOffer,
};

const actions = {
    change_current_step: ({commit}, increment) => {
        const updatedStep = increment + state.currentStep;
        commit('setStep', updatedStep)
    },

    change_choice: ({commit}, choice) => {
        commit('setChoice', choice)
    },

    change_context: ({commit}, context) => {
        commit('setContext', context);
    },

    reset_onboarding: ({commit}) => {
        commit('reset_onboarding')
    }
};

const mutations = {
    setChoice: (state, choice) => (state.choice = choice),
    setStep: (state, updatedStep) => (state.currentStep = updatedStep),
    setContext: (state, context) => (state.context = context),
    reset_onboarding: (state) => {
        state.currentStep = 1;
        state.choice = '';
        state.context = 'onboarding';
        state.worker = {};
        state.workerOffer = {};
    }
};

export default { state, getters, actions, mutations }