const state = {
    current_carousel_index: 0,
    match_work_location: ''
}

const getters = {
    current_carousel_index: state => state.current_carousel_index,
    max_carousel_index: state => state.match_work_location === 'regular' ? 10 : 9
};

const actions = {
    set_match_work_location: ({commit}, location) => {
        commit('set_match_work_location', location);
    },

    carousel_index_next: ({ commit, getters, state}) => {
        const max_carousel_index = getters.max_carousel_index;
        if(state.current_carousel_index !== max_carousel_index) {
            commit('set_carousel_index', 1);
        }
    },

    carousel_index_previous: ({ commit, state}) => {
        if(state.current_carousel_index !== 0) {
            commit('set_carousel_index', -1);
        }
    },
};

const mutations = {
    set_match_work_location(state, location) {
        state.match_work_location = location;
    },
    
    set_carousel_index(state, value) {
        state.current_carousel_index = state.current_carousel_index + value;
    },
};

export default { state, getters, actions, mutations }