import Vuex from 'vuex'
import Vue from 'vue'

import Navigation from './modules/navigation';
import Authentication from './modules/authentication';
import OnBoarding from './modules/onboarding';
import MatchDetail from './modules/match_detail';

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        Authentication,
        Navigation,
        OnBoarding,
        MatchDetail
    }
})