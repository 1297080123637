import Vue from 'vue';
import VueI18n from 'vue-i18n';

//fr
import language_fr              from './content/language.fr.json';
import notifications_fr         from './content/notifications/notifications.fr.json';
import authentication_fr        from './content/authentication/authentication.fr.json';
import login_fr                 from './content/authentication/login/login.fr.json';
import signup_fr                from './content/authentication/signup/signup.fr.json';
import navigation_fr            from './content/navigation/navigation.fr.json';
import sectors_fr               from './content/classification/sectors.fr.json';
import classes_fr               from './content/classification/classes.fr.json';
import jobs_fr                  from './content/classification/jobs.fr.json';
import get_started_fr           from './content/get-started/get-started.fr.json';
import step_2_fr                from './content/get-started/step-2/step-2.fr.json';
import side_bar_fr              from './content/dashboard/side-bar/side-bar.fr.json';
import cards_fr                 from './content/dashboard/cards/cards.fr.json';
import profile_fr               from './content/dashboard/profile/profile.fr.json';
import table_fr                 from './content/dashboard/table/table.fr.json';
import work_provider_offer_fr   from './content/dashboard/cards/workProvider/workProviderOffer.fr.json';
import missions_fr              from './content/dashboard/cards/workProvider/volunteer/missions.fr.json';
import demographics_fr          from './content/dashboard/cards/workProvider/volunteer/demographics.fr.json';
import errors_fr                from './content/errors/errors.fr.json';
import credits_fr               from './content/dashboard/credits.fr.json';
import pages_fr                 from './content/dashboard/pages.fr.json';
import tooltips_fr              from './content/tooltips/tooltips.fr.json';
import matches_fr               from './content/dashboard/matches/matches.fr.json';
import candidates_fr            from './content/dashboard/workProvider/candidates.fr.json';
import worker_offer_fr          from './content/dashboard/cards/workerOffer.fr.json';
import benefits_fr              from './content/dashboard/matches/benefits.fr.json';
import recommendations_fr       from './content/recommendations/recommendations.fr.json';
import retirement_calculator_fr from './content/retirement/retirement-calculator.fr.json';
import payments_fr              from './content/dashboard/payments.fr.json';
import modal_fr                 from './content/modals/modal.fr.json';
import messages_fr              from './content/dashboard/messages.fr.json';
import admin_fr                 from './content/admin/admin.fr.json';

//en
import language_en              from './content/language.en.json';
import notifications_en         from './content/notifications/notifications.en.json';
import authentication_en        from './content/authentication/authentication.en.json';
import login_en                 from './content/authentication/login/login.en.json';
import signup_en                from './content/authentication/signup/signup.en.json';
import navigation_en            from './content/navigation/navigation.en.json';
import sectors_en               from './content/classification/sectors.en.json';
import classes_en               from './content/classification/classes.en.json';
import jobs_en                  from './content/classification/jobs.en.json';
import get_started_en           from './content/get-started/get-started.en.json';
import step_2_en                from './content/get-started/step-2/step-2.en.json';
import side_bar_en              from './content/dashboard/side-bar/side-bar.en.json';
import cards_en                 from './content/dashboard/cards/cards.en.json';
import profile_en               from './content/dashboard/profile/profile.en.json';
import table_en                 from './content/dashboard/table/table.en.json';
import work_provider_offer_en   from './content/dashboard/cards/workProvider/workProviderOffer.en.json';
import missions_en              from './content/dashboard/cards/workProvider/volunteer/missions.en.json';
import demographics_en          from './content/dashboard/cards/workProvider/volunteer/demographics.en.json';
import errors_en                from './content/errors/errors.en.json';
import credits_en               from './content/dashboard/credits.en.json';
import pages_en                 from './content/dashboard/pages.en.json';
import tooltips_en              from './content/tooltips/tooltips.en.json';
import matches_en               from './content/dashboard/matches/matches.en.json';
import candidates_en            from './content/dashboard/workProvider/candidates.en.json';
import worker_offer_en          from './content/dashboard/cards/workerOffer.en.json';
import benefits_en              from './content/dashboard/matches/benefits.en.json';
import recommendations_en       from './content/recommendations/recommendations.en.json';
import retirement_calculator_en from './content/retirement/retirement-calculator.en.json';
import payments_en              from './content/dashboard/payments.en.json';
import modal_en                 from './content/modals/modal.en.json';
import messages_en              from './content/dashboard/messages.en.json';

Vue.use(VueI18n);

const messages = {
    'fr': Object.assign({}, language_fr, authentication_fr, login_fr, signup_fr, navigation_fr, sectors_fr, get_started_fr, step_2_fr, side_bar_fr, cards_fr, work_provider_offer_fr, missions_fr, demographics_fr, profile_fr, errors_fr, notifications_fr, table_fr, credits_fr, pages_fr, tooltips_fr, classes_fr, jobs_fr, matches_fr, candidates_fr, worker_offer_fr, benefits_fr, recommendations_fr, retirement_calculator_fr, payments_fr, modal_fr, messages_fr, admin_fr),
    'en': Object.assign({}, language_en, authentication_en, login_en, signup_en, navigation_en, sectors_en, get_started_en, step_2_en, side_bar_en, cards_en, work_provider_offer_en, missions_en, demographics_en, profile_en, errors_en, notifications_en, table_en, credits_en, pages_en, tooltips_en, classes_en, jobs_en, matches_en, candidates_en, worker_offer_en, benefits_en, recommendations_en, retirement_calculator_en, payments_en, modal_en, messages_en),
}

const i18n = new VueI18n({
    locale: 'fr', 
    fallbackLocale: 'en', 
    messages, 
});

export default i18n;