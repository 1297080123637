//Dashboard
const Dashboard = import( /* webpackChunkName: "Dashboard" */ "../pages/Dashboard/Dashboard.vue");

// Retirement Calculator
const RetirementCalculator = import( /* webpackChunkName: "RetirementCalculator" */ "../pages/RetirementCalculator/RetirementCalculator.vue");

//Offers
const WorkProviderOffers = import( /* webpackChunkName: "WorkProviderOffers" */ "../pages/Dashboard/Offers/WorkProvider/WorkProviderOffers.vue");
const WorkProviderOffer = import( /* webpackChunkName: "WorkProviderOffer" */ "../pages/Dashboard/Offers/WorkProvider/WorkProviderOffer.vue");
const CreateWorkProviderOffer = import( /* webpackChunkName: "CreateWorkProviderOffer" */ "../pages/Dashboard/Offers/WorkProvider/CreateWorkProviderOffer.vue");
const WorkProviderOfferCandidates = import( /* webpackChunkName: "WorkProviderOfferCandidates" */ "../pages/Dashboard/Offers/WorkProvider/WorkProviderOfferCandidates.vue");
const WorkProviderMatchDetail = import( /* webpackChunkName: "WorkProviderMatchDetail" */ "../pages/Dashboard/Offers/WorkProvider/WorkProviderMatchDetail.vue");

//Worker
const WorkerMatches = import( /* webpackChunkName: "WorkerMatches" */ "../pages/Dashboard/Offers/Worker/WorkerMatches.vue");
const WorkerRelations = import( /* webpackChunkName: "WorkerRelations" */ "../pages/Dashboard/Offers/Worker/WorkerRelations.vue");
const WorkerPreferences = import( /* webpackChunkName: "WorkerPreferences" */ "../pages/Dashboard/Offers/Worker/WorkerPreferences.vue");

//Authentication
const LogIn = import( /* webpackChunkName: "LogIn" */ "../pages/Authentication/LogIn.vue");
const ChangeTemporaryPassword = import( /* webpackChunkName: "ChangeTemporaryPassword" */ "../pages/Authentication/ChangeTemporaryPassword.vue");
const SignUp =  import( /* webpackChunkName: "SignUp" */ "../pages/Authentication/SignUp/SignUp.vue");
const ValidateEmailAddress = import( /* webpackChunkName: "ValidateEmailAddress" */ "../pages/Authentication/SignUp/ValidateEmailAddress.vue");

//Password
const ForgotPassword = import( /* webpackChunkName: "ForgotPassword" */ "../pages/Authentication/Password/ForgotPassword.vue");
const ResetPassword = import( /* webpackChunkName: "ResetPassword" */ "../pages/Authentication/Password/ResetPassword.vue");

//Get Started
const GetStarted = import( /* webpackChunkName: "GetStarted" */ "../pages/Dashboard/GetStarted/GetStarted.vue");

//Profile
const Profile = import( /* webpackChunkName: "Profile" */ "../pages/Dashboard/Profile/Profile.vue");
const CreateProfile = import( /* webpackChunkName: "CreateProfile" */ "../pages/Dashboard/Profile/Create/CreateProfile.vue");

//Settings
const Settings = import( /* webpackChunkName: "Profile" */ "../pages/Dashboard/Settings.vue");

//Location
const Locations = import( /* webpackChunkName: "Locations" */ "../pages/Dashboard/Locations/Locations.vue");
const Location = import( /* webpackChunkName: "Location" */ "../pages/Dashboard/Locations/Location.vue");

const Credits = import( /* webpackChunkName: "Credits" */ "../pages/Dashboard/Credits.vue");
const Messages = import( /* webpackChunkName: "Contacts" */ "../pages/Dashboard/Messaging/Messages.vue");
const Message = import( /* webpackChunkName: "Contacts" */ "../pages/Dashboard/Messaging/Message.vue");
const WorkProviderMessage = import( /* webpackChunkName: "Contacts" */ "../pages/Dashboard/Messaging/WorkProviderMessage.vue");
const Payments = import( /* webpackChunkName: "Payments" */ "../pages/Payments/Payments.vue");
const PaymentsSuccess = import( /* webpackChunkName: "PaymentsSuccess" */ "../pages/Payments/PaymentsSuccess.vue");
const Welcome = import( /* webpackChunkName: "Welcome" */ "../pages/Dashboard/GetStarted/Welcome.vue");
const Points = import( /* webpackChunkName: "Welcome" */ "../pages/Dashboard/Referrals/Points.vue");

const Admin = import( /* webpackChunkName: "Admin" */ "../pages/Admin/AdminDashboard.vue");

export const routes = [
    {
        path: "*",
        redirect: "/dashboard",
    },
    
    {
        path: "/dashboard",
        name: "dashboard",
        
        component: () => Dashboard,
        meta: { requiresAuth: true },
        props: {
            navigation: 'main',
            sidebar: true
        },
    },

    {
        path: "/dashboard/admin",
        name: "admin",
        
        component: () => Admin,
        meta: { 
            requiresAuth: true,
            scopes: ['admin']
        }, 
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'admin': true }
        },
    },

    //Offers
    //Work Provider
    
    {
        path: "/dashboard/work-provider/offers",
        name: "work-provider-offers",
        
        component: () => WorkProviderOffers,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        }, 
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        },
    },
    {
        path: "/dashboard/work-provider/offer/:id/create",
        name: "create-work-provider-offer",
        
        component: () => CreateWorkProviderOffer,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        }, 
        props: route => ({ 
            id: route.params.id,
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        })
    },
    {
        path: "/dashboard/work-provider/offers/candidates",
        name: "work-provider-offer-candidates",
        
        component: () => WorkProviderOfferCandidates,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        }, 
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        },
    },
    {
        path: "/dashboard/work-provider/offer/:id/:state",
        name: "work-provider-offer",
        
        component: () => WorkProviderOffer,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        }, 
        props: route => ({ 
            id: route.params.id,
            state: route.params.state,
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        })
    },
    {
        path: "/dashboard/work-provider/match/:id",
        name: "work-provider-match-detail",
        
        component: () => WorkProviderMatchDetail,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        },        
        props: route => ({
            id: route.params.id,
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        })
    },

    //Worker 
    {
        path: "/dashboard/worker/matches",
        name: "worker-matches",
        
        component: () => WorkerMatches,
        meta: { 
            requiresAuth: true,
            scopes: ['worker']
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'person': true }
        },
    },
    {
        path: "/dashboard/worker/preferences",
        name: "worker-preferences",
        
        component: () => WorkerPreferences,
        meta: { 
            requiresAuth: true,
            scopes: ['worker']
        },        
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'person': true }
        },
    },
    {
        path: "/dashboard/worker/matches/relations",
        name: "worker-relations",
        
        component: () => WorkerRelations,
        meta: { 
            requiresAuth: true,
            scopes: ['worker']
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'person': true }
        },
    },
    {
        path: "/dashboard/points",
        name: "points",
        
        component: () => Points,
        meta: { 
            requiresAuth: true,
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },
    {
        path: "/dashboard/messaging",
        name: "messaging",
        
        component: () => Messages,
        meta: { 
            requiresAuth: true,
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },
    {
        path: "/dashboard/message/:destination_user_id",
        name: "message",
        
        component: () => Message,
        meta: { 
            requiresAuth: true,
        },
        props: route => ({ 
            destination_user_id: route.params.destination_user_id,
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        })
    },
    {
        path: "/dashboard/message/work-provider/:destination_user_id",
        name: "work-provider-message",
        
        component: () => WorkProviderMessage,
        meta: { 
            requiresAuth: true,
        },
        props: route => ({ 
            destination_user_id: route.params.destination_user_id,
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        })
    },
    
    {
        path: "/dashboard/payments",
        name: "payments",
        
        component: () => Payments,
        meta: { 
            requiresAuth: true,
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        },
    },
    
    {
        path: "/dashboard/payments/setup/complete",
        name: "payments-success",
        
        component: () => PaymentsSuccess,
        meta: { 
            requiresAuth: true,
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        },
    },

    //Profile
    {
        path: "/profile",
        name: "profile",
        
        component: () => Profile,
        meta: { requiresAuth: true },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },
    {
        path: "/profile/create",
        name: "create-profile",
        
        component: () => CreateProfile,
        meta: { requiresAuth: true },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },

    //Settings
    {
        path: "/settings",
        name: "settings",
        
        component: () => Settings,
        meta: { requiresAuth: true },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },

    //Retirement Calculator
    {
        path: "/retirement-calculator",
        name: "retirement-calculator",

        component: () => RetirementCalculator,
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: false
        },
    },

    {
        path: "/retirement-calculator/embedded",
        name: "retirement-calculator-embedded",

        component: () => RetirementCalculator,
        meta: { 
            requiresAuth: true,            
            scopes: ['volunteer', 'worker']
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'person': true },
            embedded: true
        },
    },

    //Authentication
    {
        path: "/login",
        name: "login",

        component: () => LogIn,
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: true
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },

    {
        path: "/password/change",
        name: "change-temporary-password",

        component: () => ChangeTemporaryPassword,
        meta: { 
            requiresAuth: true,
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'both': true }
        },
    },

    {
        path: "/signup",
        name: "signup",

        component: () => SignUp,
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: true
        }
    },

    {
        path: "/signup/person",
        name: "signup-person",

        component: () => SignUp,
        props: { user_type: 'person' },
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: true
        }
    },

    {
        path: "/signup/employer",
        name: "signup-employer",

        component: () => SignUp,
        props: { user_type: 'work_provider' },
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: true
        }
    },

    {
        path: "/signup/validate",
        name: "validate-email-address",

        component: () => ValidateEmailAddress,
        props: (route) => ({ token: route.query.t }),
        meta: { 
            requiresAuth: false 
        }
    },
    
    //Password
    {
        path: "/password/forgot",
        name: "forgot-password",

        component: () => ForgotPassword,
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: true
        }
    },

    {
        path: "/password/reset",
        name: "reset-password",

        props: (route) => ({ token: route.query.t }),
        component: () => ResetPassword,
        meta: { 
            requiresAuth: false,
            blockWhenAuthenticated: true
        }
    },

    //Get Started
    {
        path: "/dashboard/get-started",
        name: "get-started",

        component: () => GetStarted,
        meta: { requiresAuth: true },
        props: {
            navigation: 'simple',
            sidebar: false,
            scope: { 'both': true }
        },
    },
    {
        path: "/dashboard/welcome",
        name: "welcome",

        component: () => Welcome,
        meta: { requiresAuth: true },
        props: {
            navigation: 'simple',
            sidebar: false,
            scope: { 'both': true }
        },
    },
    
    //Locations
    {
        path: "/dashboard/:type/locations",
        name: "locations",
        
        component: () => Locations,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        },
        props: route => ({ 
            type: route.params.type,
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        })
    },

    {
        path: "/dashboard/:type/location/create",
        name: "create-location",
        
        component: () => Location,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        },
        props: route => ({ 
            type: route.params.type,
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        })
    },

    {
        path: "/dashboard/:type/location/:id",
        name: "update-location",
        
        component: () => Location,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        },
        props: route => ({ 
            type: route.params.type,
            id: route.params.id,
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        })
    },
    
    {
        path: "/dashboard/credits",
        name: "credits",
        
        component: () => Credits,
        meta: { 
            requiresAuth: true,
            scopes: ['workProvider']
        },
        props: {
            navigation: 'main',
            sidebar: true,
            scope: { 'work-provider': true }
        },
    }

]