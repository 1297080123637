import Vue from "vue";
import Router from "vue-router";
import { routes } from './routes.js'

import store from '../store/index'
import i18n from '../plugins/i18n';

//Components
Vue.use(Router);

let router = new Router({
    linkExactActiveClass: "active",
    mode: "history",
    routes,

    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    store.dispatch('fetchToken');
    const is_authenticated = store.getters['is_authenticated'];
    const scopes = store.getters['scopes'];
    const reinitialize_temp_password = store.getters['reinitialize_temp_password'];
    const onboarding_process_completed = store.getters['onboardingProcessCompleted'];

    const sidebar_state = store.getters['sidebar_state'];
    const breakpoint = store.getters['breakpoint'];
    if(sidebar_state === 'expanded' && breakpoint === 'mobile') {
        store.dispatch('toggle_sidebar_state');
    }

    if(reinitialize_temp_password && to.name !== 'change-temporary-password') { 
        if(from.name && from.name !== 'login') {
            next({ name: 'change-temporary-password' }) 
        } else {
            next({ name: 'change-temporary-password' }) 
        }
    }
    
    if(to.name === 'dashboard' && !onboarding_process_completed) {
        next({ name: 'get-started' })
    }
    
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!is_authenticated) {
            store.dispatch('removeToken');
            
            if(to && to.query) {
                next({ name: 'login', query: { ...to.query, redirect: to.path } })
            }else {
                next({ name: 'login', query: { redirect: to.path } })
            }

        } else if (to.name === 'dashboard') {
            if(store.getters['is_person']) {
                if(store.getters['isAllowedVolunteer']) {
                    next({ name: 'volunteer-matches' })
                }else if (store.getters['isAllowedWorker']) {
                    next({ name: 'worker-matches' })
                }else {
                    next();
                }
            }else if (store.getters['isWorkProvider']) {
                next({ name: 'work-provider-offer-candidates' })
            }else if(store.getters['is_admin']) {
                next({ name: 'admin' })
            }else {
                next()
            }
        } else if (to.matched.some(record => record.meta.scopes)) {
            if(to.meta && to.meta.scopes) {
                const requiredScopes = to.meta.scopes;
                
                let allowed = false;
                requiredScopes.forEach(scope => {
                    if(scopes.includes(scope)) {
                        allowed = true;
                    }
                })

                if(allowed) { next(); }
                else { 
                    next({name: 'dashboard'});
                    Vue.prototype.$toasted.error(
                        i18n.t('errors.backend.unauthorized'),
                        { icon: 'circle-exclamation' }
                      )
                }
            }else {
                next()
            }
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.blockWhenAuthenticated)) { 
        if(is_authenticated) {
            next({ name: 'dashboard' })
        }else {
            next()
        }
    } else {
        next()
    }
})

export default router;