const xss = require('xss');
const moment = require('moment');

import { validate_time_slot, calculate_hours_per_week_from_work_times, validate_phone_number, re_order_work_days, is_date_today } from './testable_utils';

import i18n from '../plugins/i18n.js';
import router from '../routing/router';
import store from '../store/index';
import constants from './constants.js';

//eslint-disable-next-line
const postal_code_regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const utils = {
    _validate_time_slot: validate_time_slot,
    _calculate_hours_per_week_from_work_times: calculate_hours_per_week_from_work_times,
    _validate_phone_number: validate_phone_number,
    _re_order_work_days: re_order_work_days,
    _is_date_today: is_date_today,

    _isInteger: function(e) {
        e = (e) ? e : window.event;
        const code = (e.which) ? e.which : e.keyCode;
        
        if ((code > 31 && (code < 48 || code > 57))) {
            e.preventDefault();
        } else {
            return true;
        }
    },
    
    _isFloat: function(e, input) {
        e = (e) ? e : window.event;
        const code = (e.which) ? e.which : e.keyCode;
        
        if(input.toString().includes('.') && code === 46) { e.preventDefault(); }
        else if (code > 31 && (code < 48 || code > 57) && code !== 46) {
            e.preventDefault();
        } else {
            return true;
        }
    },

    validate_hours_per_week: function(e, input) {
        e = (e) ? e : window.event;
        const code = (e.which) ? e.which : e.keyCode;

        const regex_trailing_test = /\.\d\d/
        const regex_validatate_not_five_0 = /\.5/
        const regex_validatate_two_five = /\.2/
        const regex_validatate_seven_five = /\.7/
        
        if(regex_trailing_test.test(input.toString())) { e.preventDefault(); }
        else if(regex_validatate_not_five_0.test(input.toString())) { e.preventDefault(); }
        else if(regex_validatate_two_five.test(input.toString()) && code !== 53) { e.preventDefault(); }
        else if(regex_validatate_seven_five.test(input.toString()) && code !== 53) { e.preventDefault(); }
        else if (input === '2.5') { e.preventDefault(); }
        else if (parseFloat(input) >= 40) { e.preventDefault(); }
        else if (input.length === 0 && code === 46) { e.preventDefault(); }
        else if (input.includes('.') && code !== 50 && input.includes('.') && code !== 53 && input.includes('.') && code !== 55) { e.preventDefault(); }
        else { return this._isFloat(e, input); }
    },

    _translateLabel: function(key) {
        return i18n.t(key);
    },

    _translateWorkProviderType: function(key) {
        return i18n.t(`workProvider.type.${key}`);
    },

    _translateProvince: function(key) {
        return i18n.t(`language.words.provinces.long.${key}`);
    },

    _translateLanguageToLongVersion: function(key) {
        if(key === 'fr')
            return i18n.t('language.fr.long');

        if(key === 'en')
            return i18n.t('language.en.long');
    },
    
    _translateLabelForSectors: function(sector) {
        return i18n.t(`sectors.${sector.key}`);
    },

    _translateLabelForJobClass: function(obj) {
        return i18n.t(`classes.${obj.code}`);
    },
    
    _translateLabelForJob: function(obj) {
        return i18n.t(`jobs.${obj.key}`);
    },

    _translateLabelForExperienceLevel: function(key) {
        return i18n.t(`workerOffer.card.create.classification.experienceLevels.choices.${key}`)
    },

    _navigate_to_path: function(dest) {
        reset_navigation();
        router.push({path: dest}).catch(() => {})
    },

    _navigate_to_path_with_query: function(dest, query) {
        reset_navigation();
        router.push({path: dest, query: query}).catch(() => {})
    },  

    _navigate_to_name: function(dest, close_menu = null) {
        reset_navigation();
        if(close_menu) { store.dispatch('toggle_sidebar_state') }
        router.push({name: dest}).catch(() => {})
    },

    _navigate_back: function() {
        reset_navigation();
        router.back()
    },

    _navigate_to_name_with_params: function(dest, params, close_menu = null) {
        reset_navigation();
        if(close_menu) { store.dispatch('toggle_sidebar_state') }
        router.push({name: dest, params: params})
    },

    _navigate_to_name_with_query: function(dest, query) {
        reset_navigation();        
        router.push({name: dest, query: query})
    },

    _navigate_to_name_with_query_and_params: function(dest, query, params) {
        reset_navigation();
        router.push({name: dest, query: query, params: params})
    },

    _navigateToURL: function(url) {
        reset_navigation();
        router.push(url).catch(() => {})
    },

    _validate_postal_code_format: function(input) {
        return postal_code_regex.test(input);
    },
    
    _validateEmail: function(input) {
        return email_regex.test(input);
    },

    _cleanXSS: function(data) {
        for (const key in data) {
            if(typeof data[key] !== 'object' && 
               typeof data[key] !== 'undefined' && 
               typeof data[key] !== 'boolean' && 
               typeof data[key] !== 'number') {
                data[key] = xss(data[key]);
            }
        }

        return data;
    },
    
    _re_order_work_times: function(data) {
        const sortBy = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        return customSort({data, sortBy, sortField: 'day'});
    },

    _re_order_salaries: function(data) {
        const sortBy = ['none', 'experienced', 'expert'];
        return customSort({data, sortBy, sortField: 'experienceLevel'});
    },

    _validateWorkTimes: function(workTimes) {
        const self = this; let valid = true;

        for(let i = 0; i < workTimes.length; i++) {
            const day = workTimes[i];
            for(let d = 0; d < day.timeSlots.length; d++) {
                const slot = day.timeSlots[d];
                if(!slot['start'].valid || slot['start'].value === '' || !self._validate_time_slot(slot['start'].value))
                    valid = false;

                if(!slot['end'].valid || slot['end'].value === '' || !self._validate_time_slot(slot['end'].value))
                    valid = false;
            }
        }

        return valid;
    },

    _initializeWorkTimes: function(workTimes) {
        for(let i = 0; i < workTimes.length; i++) {
            const day = workTimes[i];
            day.active = true;
            
            for(let d = 0; d < day.timeSlots.length; d++) {
                day.timeSlots[d]['start'].valid = true;                         
                day.timeSlots[d]['start'].disabled = false;                         
                day.timeSlots[d]['start'].errorKey = "";     
                
                day.timeSlots[d]['end'].valid = true;                         
                day.timeSlots[d]['end'].disabled = false;                         
                day.timeSlots[d]['end'].errorKey = "";   
            }
        }
    },

    _computePercentageHTML: function(input) {
        if(!input && input !== 0) { return `<span class="transparent">0%</span>` }
        let color;

        if(input === 1 || input % 1 !== 0) {
            input = input * 100;
        }
        
        if(input >= 85) {
            color = 'green';
        }else if(input >= 75) {
            color = 'yellow';
        }else {
            color = 'red';
        }

        if(input === 125) {
            input = 100;
        }

        return `<span class="${color}">${input}%</span>`
    },

    _generate_score_class: function(match, key) {
        let score = match.calculationDetails[key];

        if(score === 1 || score % 1 !== 0) {
            score = score * 100;
        }

        if(score >= 85) {
            return 'green';
        }else if(score >= 75) {
            return 'yellow';
        }else {
            return 'red';
        }
    },

    _translateMatchStage: function(stage) {
        return i18n.t(`matches.stages.${stage}`)
    },

    _translateMatchStageLC: function(stage) {
        return i18n.t(`matches.stages.lc.${stage}`)
    },

    _translate_priority: function(key) {
        return i18n.t(`profile.worker.card.priority.values.${key}`)
    },

    _formatDate: function(date) {
        if(date) {
            return new Date(date).toLocaleDateString('en-GB');
        }
    },

    _addDistanceUnits: function(input) {
        return `${input} km`
    },

    _generateContactMethodLabel: function(contactMethod, client) {
        if(client === 'workProvider'){
            return `<span>${i18n.t('matches.detail.workProvider.modal.unlockCandidate.sharedInformation.contactOtherPartyBy')} <b><em>${i18n.t(`matches.detail.workProvider.modal.unlockCandidate.sharedInformation.methods.${contactMethod.method}`)}</em></b> au <b><em>${contactMethod.info}</em></b></span>`;
        }else {
            return `<span>${i18n.t('matches.detail.person.modal.interested.sharedInformation.contactOtherPartyBy')} <b><em>${i18n.t(`matches.detail.workProvider.modal.unlockCandidate.sharedInformation.methods.${contactMethod.method}`)}</em></b> au <b><em>${contactMethod.info}</em></b></span>`;
        }
    },

    _formatSalary: function(salary) {
        if(salary.type === 'hourly') {
            return salary.amount.replace('.', ',');
        }else {
            return salary.amount.slice(0, salary.amount.length - 3) + ',' + salary.amount.slice(salary.amount.length - 3)
        }
    },

    _roundDistance: function(distance) {
        return distance.toFixed(0)
    },

    _format_yearly_cash: function(amount) {
        const as_string = amount.toString().trim();
        if(as_string.length === 4) {
            return as_string.slice(0, 1) + ',' + as_string.slice(1, 4) + ' $'
        } else if(as_string.length === 5) {
            return as_string.slice(0, 2) + ',' + as_string.slice(2, 5) + ' $'
        } else if (as_string.length === 6) {
            return as_string.slice(0, 3) + ',' + as_string.slice(3, 6) + ' $'
        }else {
            return `${as_string} $`
        }
    },

    _format_transaction_amount(amount) {
        return (amount / 100).toString().replace('.', ',') + '$';
    },

    _format_phone_number: function(input) {
        return `(${input.slice(0,3)}) ${input.slice(3,6)}-${input.slice(6,10)}`
    },

    _validate_postal_code_location: function(input) {
        const prefixes = ['G0A', 'G0R', 'G1', 'G2', 'G3', 'G6', 'G7A'];
        let valid = false;

        if (utils._validate_postal_code_format(input)) {
            prefixes.forEach((prefix) => {
                if (input.startsWith(prefix)) { valid = true; }
            });

            return valid;
        }

        return false;
    },

    _open_modal: function() {
        document.body.classList.add('modal-open')
    },

    _close_modal: function() {
        document.body.classList.remove('modal-open')
    },
    
    _format_date: function(date, with_time = false) {
        return with_time ? moment(date).format('DD/MM/YYYY, H:mm') : moment(date).format('DD/MM/YYYY')
    },

    _validate_hourly_salary: function(input) {
        if(input) {
            let amount = input.toString().replace(',', '.').replace(/[^0-9.]/g, '').trim();
            if (!amount.includes('.')) {
                if (amount.length === 4 || amount.length === 5) {
                    amount = `${amount.slice(0, amount.length - 2)}.${amount.slice(amount.length - 2)}`;
                }
            }

            return amount >= constants.MINIMUM_SALARY 
        }

        return false;
    },

    _return_time_diff_string: function(date) {
        const now = new Date();
        const minutes = moment(now).diff(moment(date), 'minutes');
        const hours = moment(now).diff(moment(date), 'hours');
        const days = moment(now).diff(moment(date), 'days');

        if(minutes === 0) { return `1 ${i18n.t('language.minute')}` }
        else if (minutes === 1) { return `${minutes} ${i18n.t('language.minute')}` }
        else if (minutes < 60)  { return `${minutes} ${i18n.t('language.minutes')}` }
        else if (hours === 1)  { return `${hours} ${i18n.t('language.hour')}` }
        else if (hours > 1)  { return `${hours} ${i18n.t('language.hours')}` }
        else if (days === 1)  { return `${days} ${i18n.t('language.day')}` }
        else  { return `${days} ${i18n.t('language.days')}` }
    }
}

const customSort = ({data, sortBy, sortField}) => {
    const sortByObject = sortBy.reduce((obj, item, index) => {
        return {
            ...obj,
            [item]: index
        }
    }, {})

    return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]])
}

const reset_navigation = () => {
    utils._close_modal();
}

export default utils;