import i18n from '../../plugins/i18n';

const cookies = require('js-cookie');

const state = {
    currentLanguage: 'fr',
    sidebar_state: '',
    show_referral_modal: false,
    show_support_modal: false,
    show_payment_method_needed_modal: false,
    show_install_instructions: false,
    show_install_on_mobile_modal: false,
    show_stats_do_not_match_modal: false,
    container_margin: 0,
    meta: {},
    number_of_unread_messages: 0,
    messages_not_seen_by_sender: {},
    breakpoint: '',

    utm_source: 'none',
    utm_medium: 'none',
    utm_content: 'none',
    utm_term: 'none',
    utm_campaign: 'none',
    native_ios: false
}

const getters = {
    currentLanguage: state => state.currentLanguage,
    breakpoint: state => state.breakpoint,
    sidebar_state: state => state.sidebar_state,
    referral_modal_state: state => state.show_referral_modal,
    support_modal_state: state => state.show_support_modal,
    payment_method_needed_modal_state: state => state.show_payment_method_needed_modal,
    stats_do_not_match_modal_state: state => state.show_stats_do_not_match_modal,
    container_margin: state => state.container_margin,
    show_install_instructions: state => state.show_install_instructions,
    show_install_on_mobile_modal: state => state.show_install_on_mobile_modal,
    show_help_modal: state => state.show_help_modal,
    meta: state => state.meta,
    number_of_unread_messages: state => state.number_of_unread_messages,
    messages_not_seen_by_sender: state => state.messages_not_seen_by_sender,
    not_current_language(state) {
        return state.currentLanguage === 'fr' ? 'en' : 'fr';
    },

    utm_source: state => state.utm_source,
    utm_medium: state => state.utm_medium,
    utm_content: state => state.utm_content,
    utm_term: state => state.utm_term,
    utm_campaign: state => state.utm_campaign,
    native_ios: state => state.native_ios,
};

const actions = {
    setCurrentLanguage: ({commit}) => {
        let currentLanguage = state.currentLanguage;

        if (localStorage.getItem("current_language")) {
            currentLanguage = localStorage.getItem("current_language");
            i18n.locale = currentLanguage;

            commit('setCurrentLanguage', currentLanguage)
        } else {
            localStorage.setItem("current_language", currentLanguage);
        }
    },

    change_current_language: ({commit}, currentLanguage) => {
        if(currentLanguage === 'fr'){
            localStorage.setItem("current_language", 'en');
            i18n.locale = 'en';

            commit('setCurrentLanguage', 'en');
        }else {
            localStorage.setItem("current_language", 'fr');
            i18n.locale = 'fr';

            commit('setCurrentLanguage', 'fr')
        }
    },

    toggle_sidebar_state: ({commit}, from_mobile_nav = false) => {
        if(from_mobile_nav && state.sidebar_state === 'expanded') {
            return;
        }else {
            const val = state.sidebar_state === 'expanded' ? 'collapsed' : 'expanded';
            commit('toggle_sidebar_state', val);
        }

    },
    
    set_sidebar_state: ({commit}, val) => {
        commit('toggle_sidebar_state', val);
    },

    set_referral_modal_state: ({commit}, val) => {
        commit('set_referral_modal_state', val)
    },
    
    set_support_modal_state: ({commit}, val) => {
        commit('set_support_modal_state', val)
    },

    set_payment_method_needed_modal_state: ({commit}, val) => {
        commit('set_payment_method_needed_modal_state', val)
    },

    set_stats_do_not_match_modal_state: ({commit}, val) => {
        commit('set_stats_do_not_match_modal_state', val)
    },

    set_container_margin: ({commit}, val) => {
        commit('set_container_margin', val)
    },
    
    set_show_install_instructions: ({commit}, val) => {
        commit('set_show_install_instructions', val)
    },

    set_show_install_on_mobile_modal: ({commit}, val) => {
        commit('set_show_install_on_mobile_modal', val)
    },

    set_meta: ({commit}, val) => {
        commit('set_meta', val)
    },

    set_number_of_unread_messages: ({commit}, val) => {
        commit('set_number_of_unread_messages', val)
    },

    set_messages_not_seen_by_sender: ({commit}, val) => {
        commit('set_messages_not_seen_by_sender', val)
    },

    set_breakpoint: ({commit}, val) => {
        commit('set_breakpoint', val)
    },

    fetch_utm_source: ({commit}) => {
        const utm_source = cookies.get('utm_source_app');
        if(utm_source) {
            commit('set_utm_source', { utm_source, override: false });
        }
    },

    fetch_utm_medium: ({commit}) => {
        const utm_medium = cookies.get('utm_medium_app');
        if(utm_medium) {
            commit('set_utm_medium', { utm_medium, override: false });
        }
    },

    fetch_utm_content: ({commit}) => {
        const utm_content = cookies.get('utm_content_app');
        if(utm_content) {
            commit('set_utm_content', { utm_content, override: false });
        }
    },

    fetch_utm_term: ({commit}) => {
        const utm_term = cookies.get('utm_term_app');
        if(utm_term) {
            commit('set_utm_term', { utm_term, override: false });
        }
    },

    fetch_utm_campaign: ({commit}) => {
        const utm_campaign = cookies.get('utm_campaign_app');
        if(utm_campaign) {
            commit('set_utm_campaign', { utm_campaign, override: false });
        }
    },

    fetch_native_ios: ({commit}) => {
        const native_ios = cookies.get('native_ios_app');
        if(native_ios) {
            commit('set_native_ios', { native_ios: true, override: false });
        }
    },

    set_utm_source: ({commit}, payload) => { commit('set_utm_source', payload) },
    set_utm_medium: ({commit}, payload) => { commit('set_utm_medium', payload) },
    set_utm_content: ({commit}, payload) => { commit('set_utm_content', payload) },
    set_utm_term: ({commit}, payload) => { commit('set_utm_term', payload) },
    set_utm_campaign: ({commit}, payload) => { commit('set_utm_campaign', payload) },
    set_native_ios: ({commit}, payload) => { commit('set_native_ios', payload) },
};

const mutations = {
    setCurrentLanguage: (state, currentLanguage) => (state.currentLanguage = currentLanguage),
    toggle_sidebar_state: (state, set) => (state.sidebar_state = set),
    set_referral_modal_state: (state, set) => (state.show_referral_modal = set),
    set_support_modal_state: (state, set) => (state.show_support_modal = set),
    set_payment_method_needed_modal_state: (state, set) => (state.show_payment_method_needed_modal = set),
    set_stats_do_not_match_modal_state: (state, set) => (state.show_stats_do_not_match_modal = set),
    set_container_margin: (state, set) => (state.container_margin = set),
    set_show_install_instructions: (state, set) => (state.show_install_instructions = set),
    set_show_install_on_mobile_modal: (state, set) => (state.show_install_on_mobile_modal = set),
    set_meta: (state, set) => (state.meta = set),
    set_number_of_unread_messages: (state, set) => (state.number_of_unread_messages = set),
    set_messages_not_seen_by_sender: (state, set) => (state.messages_not_seen_by_sender = set),
    set_breakpoint: (state, set) => (state.breakpoint = set),

    set_utm_source(state, payload) {
        const { utm_source, override } = payload;

        state.utm_source = utm_source.trim();
        if(override) {
            cookies.set('utm_source_app', utm_source.trim(), { expires: 7, secure: true, sameSite: 'strict' });
        }
    },
    
    set_utm_medium(state, payload) {
        const { utm_medium, override } = payload;

        state.utm_medium = utm_medium.trim();
        if(override) {
            cookies.set('utm_medium_app', utm_medium.trim(), { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    set_utm_content(state, payload) {
        const { utm_content, override } = payload;

        state.utm_content = utm_content.trim();
        if(override) {
            cookies.set('utm_content_app', utm_content.trim(), { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    set_utm_term(state, payload) {
        const { utm_term, override } = payload;

        state.utm_term = utm_term.trim();
        if(override) {
            cookies.set('utm_term_app', utm_term.trim(), { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    set_utm_campaign(state, payload) {
        const { utm_campaign, override } = payload;

        state.utm_campaign = utm_campaign.trim();
        if(override) {
            cookies.set('utm_campaign_app', utm_campaign.trim(), { expires: 7, secure: true, sameSite: 'strict' });
        }
    },

    set_native_ios(state, payload) {
        const { native_ios, override } = payload;
        
        state.native_ios = native_ios;
        if(override) {
            cookies.set('native_ios_app', true, { expires: 7, secure: true, sameSite: 'strict' });
        }
    },
};

export default { state, getters, actions, mutations }